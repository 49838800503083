import { render, staticRenderFns } from "./xwTitbits.vue?vue&type=template&id=23ec0702&scoped=true&"
import script from "./xwTitbits.vue?vue&type=script&lang=js&"
export * from "./xwTitbits.vue?vue&type=script&lang=js&"
import style0 from "./xwTitbits.vue?vue&type=style&index=0&id=23ec0702&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ec0702",
  null
  
)

export default component.exports