<template>
    <div class="titbits_page">
        <div class="scrollbar-macosx scroll-content">
            <div class="activeTop">
                <div class="btn_top" :class="{selected:activeTab === index}" 
                :style="{backgroundImage:`url(${item.btnBg})`}"
                 v-for="(item,index) in tabList" :key="index"
                 @click="activeTab = index"></div>
            </div>
            <div class="activeBox">
                <video controls="controls" autoplay="autoplay" :key="tabList[activeTab].videoUrl">
                    <source :src="tabList[activeTab].videoUrl" type="video/mp4" />
                </video>
                <div class="activeTitle">{{ $t(tabList[activeTab].label) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
     data(){
        return{
            activeTab:3,
            tabList:[
                {
                    videoUrl:"https://active-sgtv2.wd222.net/mp4/2016av_new.mp4",
                    label:'LEO旗艦館週年慶活動花絮',
                    btnBg:require('@/assets/images/xw/2016btn.png')
                },
                {
                    videoUrl:"https://active-sgtv2.wd222.net/mp4/2018av_new.mp4",
                    label:'AV女優活動花絮',
                    btnBg:require('@/assets/images/xw/2018btn.png')
                },
                {
                    videoUrl:"https://active-sgtv2.wd222.net/mp4/2019av_new.mp4",
                    label:'2019 S級女優',
                    btnBg:require('@/assets/images/xw/2019btn.png')
                },
                {
                    videoUrl:"https://active-sgtv2.wd222.net/mp4/2022av.mp4",
                    label:'2022 AV × 世足',
                    btnBg:require('@/assets/images/xw/2022btn.png')
                },
            ]
        }
     }
}
</script>

<style lang="less" scoped>
.titbits_page{
    width: 100vw;
    height: var(--vh);
    overflow: hidden;
    background: #000 url(../../assets/images/xw/img_activebg.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 0 10px;
}

.activeTop{
    position: relative;
    width: fit-content;
    max-width: 740px;
    margin: 0 auto 20px;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
}

.activeBox{
    width: 100%;
    max-width: 740px;
    background: rgba(0, 0, 0, .6);
    border-radius: 5px;
    text-align: center;
    padding-top: 15px;
    margin: 0 auto;
}

.activeTitle{
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 32px;
}

.btn_top {
    background-repeat: no-repeat;
    background-position: top;
    display: block;
    height: 38px;
    width: 172px;
    cursor: pointer;
}

.btn_top.selected{
    background-position: bottom;
}
</style>